<template>
  <div class="page">
    <van-nav-bar
      fixed
      title="详情 "
      left-arrow
      @click-left="$router.go(-1)"
    ></van-nav-bar>
    <div class="alarm-detail">
      <van-cell title="设备位号" :value="infoForm.equipmentRecordNo" />
      <van-cell title="设备名称" :value="infoForm.equipmentRecordName" />
      <van-cell
        title="设备所属部门"
        :value="infoForm.equipmentDepartmentName"
      />
      <van-cell title="保养类型" :value="infoForm.typeName" />
      <van-cell title="计划保养日期" :value="infoForm.planDate" />
      <van-cell title="保养项目" :value="infoForm.project" />
      <van-cell title="备注" class="cell" />
      <van-field
        v-model="infoForm.remark"
        disabled
        rows="3"
        type="textarea"
        maxlength="1000"
      />
      <van-cell title="保养人" :value="infoForm.maintainerName" />
      <van-cell title="保养人部门" :value="infoForm.departmentName" />
      <van-cell title="实际保养日期" :value="infoForm.actualDate" />
      <van-cell title="现场照片：" class="cell" />
      <van-uploader v-model="picList" :deletable="false" multiple disabled />
    </div>
  </div>
</template>

<script>
import { getInfo } from "@/api/deciveServe";

export default {
  name: "DeviceServeDone",
  data() {
    return {
      infoForm: {},
      picList: []
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.params.id) {
        // this.$route.params.id这个id是PC端发起待办时传递的润滑保养记录id
        getInfo(this.$route.params.id).then(res => {
          // 表示从待办跳转至润滑保养详情
          this.infoForm = res;
          this.infoForm.list = (this.infoForm.files || []).map(i => {
            return {
              ...i,
              url: `${window.globalConfig.VUE_APP_FILE_URL}/file/download?downloadFlag=false&fileName=${i.id}`
            };
          });
          for (let i = 0; i < this.infoForm.list.length; i++) {
            const pic = {
              url: this.infoForm.list[i].url,
              isImage: true
            };
            this.picList.push(pic);
          }
        });
      } else {
        this.infoForm = this.$route.params.item;
        this.infoForm.list = (this.infoForm.list || []).map(i => {
          return {
            ...i,
            url: `${window.globalConfig.VUE_APP_FILE_URL}/file/download?downloadFlag=false&fileName=${i.id}`
          };
        });
        for (let i = 0; i < this.infoForm.list.length; i++) {
          const pic = {
            url: this.infoForm.list[i].url,
            isImage: true
          };
          this.picList.push(pic);
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep {
  .cell::after {
    border: none !important;
  }
}
.page {
  text-align: left;
  height: 100vh;
  background: #fff;
}
.alarm-detail {
  overflow: auto;
  padding: 58px 0 0;
  // height: calc(100vh - 58px);
}
.alarm-detail ::v-deep {
  .van-uploader {
    width: 92%;
    margin: 0 auto;
    display: flex;
    .van-uploader__wrapper {
      width: 100%;
    }
    .van-uploader__preview {
      width: 20%;
      margin-left: 6%;
      margin-right: 6%;
      margin-top: 2%;
    }
  }
  .van-uploader__upload {
    display: none;
  }
}
</style>
